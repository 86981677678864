import React from 'react'
import { images } from 'config/images'

export const APIIntegrationDevProps = {
  bannerProps: {
    bannerHeading: (
      <>
        API <span className="heading-red">integration services</span> to get
        systems and apps to work together
      </>
    ),
    bannerContent: (
      <>
        Cut down development time and costs by <br />
        integrating third-party API platforms & systems
      </>
    ),
    BannerAlt: 'API integration services',
    buttonName: 'Get free consultation!',
    buttonLink: '/contact/',
  },
  customDevProps: {
    serviceHeading: (
      <>
        <span className="highlight-head">Custom API</span> Offerings
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: (
          <>
            API Integration <br />
            Services
          </>
        ),
        serviceParagraph: (
          <>
            Integrate existing business systems or{' '}
            <strong>third-party APIs</strong> to harness data and feature
            sharing in your applications.
          </>
        ),
        serviceIcon: images.sapiIntegrationService,
        serviceAlt: 'Automation',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'Custom API Development Services',
        serviceParagraph: (
          <>
            Custom APIs securely <strong>extend the functionality</strong> of
            your application with new and existing third-party systems and
            devices.
          </>
        ),
        serviceIcon: images.scustomApiDevServices,
        serviceAlt: 'Mobile',
        serviceClass: 'pale-grey',
      },
      {
        id: '3',
        serviceHeading: (
          <>
            API as a <br />
            Service
          </>
        ),
        serviceParagraph: (
          <>
            Build an <strong>API first business</strong> by building APIs as a
            Service (APIaaS) targetting common web protocols including
            HTTP/HTTPS, XML, JSON, REST, SOAP.
          </>
        ),
        serviceIcon: images.sapiAsAService,
        serviceAlt: 'Manual',
        serviceClass: 'ice-blue',
      },
      {
        id: '4',
        serviceHeading: 'Automated API Testing Services',
        serviceParagraph: (
          <>
            We use Unit testing, Functional testing, and Load testing to{' '}
            <strong>check the performance</strong> of individual APIs, end to
            end functionality, and performance of API layers.
          </>
        ),
        serviceIcon: images.sautomatedApi,
        serviceAlt: 'Software',
        serviceClass: 'pale',
      },
      {
        id: '5',
        serviceHeading: (
          <>
            Cloud Integration <br />
            Services
          </>
        ),
        serviceParagraph: (
          <>
            <strong>Connect applications</strong> and data across multiple
            clouds by building API layers for business services using Mulesoft,
            Azure, Dell Boomi, etc.
          </>
        ),
        serviceIcon: images.scloudApp,
        serviceAlt: 'Devops',
        serviceClass: 'pale-lavender',
      },
      {
        id: '6',
        serviceHeading: 'Microservices Consulting Services',
        serviceParagraph: (
          <>
            {' '}
            <strong>Break monolithic applications</strong> into a suite of
            microservices so that each service is configured to run as a unique
            process and can be changed independently.
          </>
        ),
        serviceIcon: images.smicroServices,
        serviceAlt: 'Performance',
        serviceClass: 'very-light-pink-three',
      },
    ],
  },
  buildApplicationProps: {},
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogoLink: 'https://www.redbull.com',
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogoLink: 'https://www.pepsico.com/',
        clientLogosrc: images.pepsico,
        clientLogosrcg: images.pepsicog,
        clientLogoAlt: 'Pepsico',
      },
      {
        clientLogoLink: 'https://www.apa.org/',
        clientLogosrc: images.apag,
        clientLogosrcg: images.apa,
        clientLogoAlt: 'Americal Psychological Association',
      },
      {
        clientLogoLink: 'https://www.santander.co.uk/',
        clientLogosrc: images.santanderg,
        clientLogosrcg: images.santander,
        clientLogoAlt: 'Santander',
      },
      {
        clientLogoLink: 'https://www.bankofamerica.com/',
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogoLink: 'https://www.cisco.com/',
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogoLink: 'https://www.experian.com/',
        clientLogosrc: images.experiang,
        clientLogosrcg: images.experian,
        clientLogoAlt: 'Experian',
      },
      {
        clientLogoLink: 'https://www.fidelity.com/',
        clientLogosrc: images.fidelityg,
        clientLogosrcg: images.fidelity,
        clientLogoAlt: 'Fidelity',
      },
      {
        clientLogoLink: 'https://www.cameo.com/',
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogoLink: 'https://www.hsbc.com/',
        clientLogosrcg: images.hsbc,
        clientLogosrc: images.hsbcg,
        clientLogoAlt: 'HSBC',
      },
      {
        clientLogoLink: 'https://www.jpmorganchase.com/',
        clientLogosrc: images.jpmg,
        clientLogosrcg: images.jpm,
        clientLogoAlt: 'JP Morgan',
      },
      {
        clientLogoLink: 'https://www.larvol.com/',
        clientLogosrc: images.larvolg,
        clientLogosrcg: images.larvol,
        clientLogoAlt: 'Larvol',
      },
      {
        clientLogoLink: 'https://www.natwestgroup.com',
        clientLogosrc: images.netwestg,
        clientLogosrcg: images.netwest,
        clientLogoAlt: 'NetWest',
      },
      {
        clientLogoLink: 'https://solvpath.com/',
        clientLogosrc: images.solvepathg,
        clientLogosrcg: images.solvepath,
        clientLogoAlt: 'Solve Path',
      },
      {
        clientLogoLink: 'https://nextbigideaclub.com/',
        clientLogosrc: images.nbicg,
        clientLogosrcg: images.nbic,
        clientLogoAlt: 'NBIC',
      },
      {
        clientLogoLink: 'https://www.news.co.uk/',
        clientLogosrc: images.newsukg,
        clientLogosrcg: images.newsuk,
        clientLogoAlt: 'News UK',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        Recent <span className="highlight-head">case studies</span>
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  customReviewSliderProps: {
    heading: (
      <>
        Customer <span className="highlight-head">testimonials</span>
      </>
    ),
    textTestimonialProps: [
      {
        clientName: 'Jansen Myburgh',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          "Decision was made easier after reviewing their employee's feedback",
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087790',
      },
      {
        clientName: 'Danielle Beben',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'The communication line was always open and they are very approachable',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346088120',
      },
      {
        clientName: 'Jawann Swislow',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They took care of design and even architecture side as well',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087960',
      },
      {
        clientName: 'Justin Wollman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform has variety of engineers to meet our current and future demands',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087717',
      },
      {
        clientName: 'Olivia Butters',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'We needed someone who could grasp our vision',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087638',
      },
      {
        clientName: 'Ritwik Pavan',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'I have been thrilled to have such a dedicated team',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087564',
      },
      {
        clientName: 'Jeff Petreman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform is real asset to the TransAction Logistics team.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087854',
      },
      {
        clientName: 'Andy Gibson',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They’ve always been able to come up with solution through technology.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/368290760',
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        Benefits of working with
        <br /> <span className="highlight-head"> Simform</span>
      </>
    ),
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Integrator experienced with API driven approach',
        workingBenefitsContent: (
          <>
            With the rise of <strong>API first business movement</strong>,
            companies are opening up access to their functionalities and data
            for newer revenue generation streams. Opening up opportunities for
            mission-critical business applications and new software applications
            to build on top of these APIs.
          </>
        ),
        benefitImgAlt: 'API Integration',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsContent: (
          <>
            Simform has been a proponent and{' '}
            <strong>system integration partner</strong> of many PaaS, SaaS, and
            Software solutions like Twilio, Dell Boomi, Amazon AWS, Google
            Cloud, Nexmo, Stripe, Cloudera, etc. We have worked hand-in-hand
            with them to develop and integrate a range of APIs for b2b and
            consumer-facing applications. Our API integration projects aim to
            save you money by <strong>reducing development time</strong> and cut
            costs.
          </>
        ),
        benefitImgAlt: '3rd Party API Integration',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Review and assessment',
        workingBenefitsContent: (
          <>
            First, let us assess the API documentation of different providers
            and suggest the best fit. We then define the{' '}
            <strong>target architecture of apps</strong> and help refactor
            monolithic apps if needed.
          </>
        ),
        benefitImgAlt: 'API documentation',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Standardized API development',
        workingBenefitsContent: (
          <>
            We expertise in a large <strong>variety of APIs</strong> using SOAP,
            REST and XML-RPC as well as JSON and other protocols. We also help
            develop APIs for mobile, desktop browser apps, databases, and
            intranet systems.
          </>
        ),
        benefitImgAlt: 'Standardized API development',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'APIs designed for scale',
        workingBenefitsContent: (
          <>
            Our APIs only expose the right pieces of your applications securely
            and efficiently. <strong>Evolutionary architecture</strong> gives
            flexibility for incremental additions. APIs are made to be platform
            independent so that any app can call the APIs.
          </>
        ),
        benefitImgAlt: 'Scalable API',
      },
    ],
  },
  developSoftAgileProps: {
    heading: (
      <>
        Our <span className="highlight-head">API development process </span>
      </>
    ),
    headingLeftImgProps: [
      {
        src: images.serviceUser2,
        srcSet: images.serviceUser22x,
      },
      {
        src: images.serviceUser3,
        srcSet: images.serviceUser32x,
      },
      {
        src: images.serviceUser1,
        srcSet: images.serviceUser12x,
      },
    ],
    headingRightImgProps: [
      {
        src: images.serviceUser4,
        srcSet: images.serviceUser42x,
      },
      {
        src: images.serviceUser5,
        srcSet: images.serviceUser52x,
      },
      {
        src: images.serviceUser6,
        srcSet: images.serviceUser62x,
      },
    ],
    workFlowFirstRows: [
      {
        workFlowClass: 'very-light-blue',
        workFlowCount: '1',
        workFlowIcon: images.ServiceSprintPlanning,
        workFlowIconAlt: 'Team',
        workFlowTitle: 'Sprint planning',
        workFlowParagraph:
          'Sprint roadmap is a collective planning effort. Team members collaborate to clarify items and ensure shared understanding.',
        workFlowArrowImg: images.iconFlowArrow1,
      },
      {
        workFlowClass: 'duck-egg-blue',
        workFlowCount: '2',
        workFlowIcon: images.ServiceTechArchitecture,
        workFlowIconAlt: 'Sprint',
        workFlowTitle: 'Tech architecture',
        workFlowParagraph:
          'We break monolithic apps into microservices. Decoupling the code allows teams to move faster and more independently.',
        workFlowArrowImg: images.iconFlowArrow2,
      },
      {
        workFlowClass: 'light-peach',
        workFlowCount: '3',
        workFlowIcon: images.ServiceStandups,
        workFlowIconAlt: 'Tech',
        workFlowTitle: 'Stand-ups & weekly demos',
        workFlowParagraph:
          'Stand ups, weekly demos, and weekly reviews make sure everyone is on the same page and can raise their concerns.',
        workFlowArrowImg: images.iconFlowArrow3,
      },
      {
        workFlowClass: 'light-peach-three',
        workFlowCount: '4',
        workFlowIcon: images.ServiceCodeReviews,
        workFlowIconAlt: 'StandUps',
        workFlowTitle: 'Code reviews',
        workFlowParagraph:
          'Code reviews before release help detect issues like memory leaks, file leaks, performance signs, and general bad smells.',
        workFlowArrowImg: images.iconFlowArrow4,
      },
    ],
  },
  industriesServeProps: {
    heading: (
      <>
        <span className="highlight-head">Industries</span> We Serve
      </>
    ),
    tabContents: [
      {
        tabName: 'Sales',
        contentHeading: <>Sales API integration</>,
        contentPara:
          'Integrate Sales API into your Custom Software to seamlessly manage your CRM with in-built phone and email, to-dos, appointment, pipeline views and lead management.',
        contentSingleProps: [
          {
            singleblkImg: images.sales1,
            singleblkImg2x: images.sales12x,
          },
          {
            singleblkImg: images.sales2,
            singleblkImg2x: images.sales22x,
          },
          {
            singleblkImg: images.sales3,
            singleblkImg2x: images.sales32x,
          },
        ],
      },
      {
        tabName: 'Marketing',
        contentHeading: <>Marketing & automation</>,
        contentPara:
          'Automate your marketing on-the-go! Manage your audience, find new customers, target custom audience, ad creation & management, reporting and analytics.',
        contentSingleProps: [
          {
            singleblkImg: images.marketing1,
            singleblkImg2x: images.marketing12x,
          },
          {
            singleblkImg: images.marketing2,
            singleblkImg2x: images.marketing22x,
          },
          {
            singleblkImg: images.marketing3,
            singleblkImg2x: images.marketing32x,
          },
        ],
      },
      {
        tabName: 'Communication',
        contentHeading: <>SMS & e-mail gateway integration</>,
        contentPara:
          ' Integrate communication APIs to send sms and email from your mobile app with custom user targeting, user analytics, scheduling and drip marketing. ',
        contentSingleProps: [
          {
            singleblkImg: images.smsemail1,
            singleblkImg2x: images.smsemail12x,
          },
          {
            singleblkImg: images.smsemail2,
            singleblkImg2x: images.smsemail22x,
          },
          {
            singleblkImg: images.smsemail3,
            singleblkImg2x: images.smsemail32x,
          },
        ],
      },
      {
        tabName: 'eCommerce',
        contentHeading: <>Shopping API integration</>,
        contentPara:
          'Integrate your e-commerce software with shopping platforms. Update product catalogues & details, user analytics and inventory from a single platform.',
        contentSingleProps: [
          {
            singleblkImg: images.shopping1,
            singleblkImg2x: images.shopping12x,
          },
          {
            singleblkImg: images.shopping2,
            singleblkImg2x: images.shopping22x,
          },
          {
            singleblkImg: images.shopping3,
            singleblkImg2x: images.shopping32x,
          },
        ],
      },
      {
        tabName: 'Logistics',
        contentHeading: <>Shipping gateways</>,
        contentPara:
          'Integrate shipping capabilities directly to your mobile applications or e-commerce websites that helps you align your shipping & logistics activities.',
        contentSingleProps: [
          {
            singleblkImg: images.shipping1,
            singleblkImg2x: images.shipping12x,
          },
          {
            singleblkImg: images.shipping2,
            singleblkImg2x: images.shipping22x,
          },
          {
            singleblkImg: images.shipping3,
            singleblkImg2x: images.shipping32x,
          },
        ],
      },
      {
        tabName: 'Social Login',
        contentHeading: <>Social login gateways</>,
        contentPara:
          'Simple sign-in process for end users using third-party social login providers, accesses richer user profiles, gaining additional information & user analytics.',
        contentSingleProps: [
          {
            singleblkImg: images.sociallogin1,
            singleblkImg2x: images.sociallogin12x,
          },
          {
            singleblkImg: images.sociallogin2,
            singleblkImg2x: images.sociallogin22x,
          },
          {
            singleblkImg: images.sociallogin3,
            singleblkImg2x: images.sociallogin32x,
          },
        ],
      },
      {
        tabName: 'Weather',
        contentHeading: <>Weather API integration</>,
        contentPara:
          'Get access to current weather data, hourly forecasts, UV index, air pollution and much more directly into your application by integrating weather APIs. ',
        contentSingleProps: [
          {
            singleblkImg: images.weather1,
            singleblkImg2x: images.weather12x,
          },
          {
            singleblkImg: images.weather2,
            singleblkImg2x: images.weather22x,
          },
          {
            singleblkImg: images.weather3,
            singleblkImg2x: images.weather32x,
          },
        ],
      },
      {
        tabName: 'Navigation',
        contentHeading: <>In-built maps integration</>,
        contentPara:
          'Integrate maps API into your mobile application to get seamless navigation, traffic forecasts, satellite & street view and saved places.',
        contentSingleProps: [
          {
            singleblkImg: images.inbuilt1,
            singleblkImg2x: images.inbuilt12x,
          },
          {
            singleblkImg: images.inbuilt2,
            singleblkImg2x: images.inbuilt22x,
          },
          {
            singleblkImg: images.inbuilt3,
            singleblkImg2x: images.inbuilt32x,
          },
        ],
      },
    ],
  },
  apisolutionProps: {
    heading: (
      <>
        Our <span className="highlight-head">API solutions</span>
      </>
    ),
    sectionPara: 'Integrate & Develop bespoke API with Simform Solutions',
    solutionList: [
      {
        className: 'pale-grey',
        icon: images.apiPaymentGateways,
        title: 'Payment Gateways',
        lists: [
          'Paypal',
          'Authorize.net',
          '2Checkout',
          'BillDesk',
          'PayU',
          'EBS',
          'Sage Pay',
        ],
      },
      {
        className: 'pale-green',
        icon: images.apiShippingGateway,
        title: (
          <>
            Shipping <br />
            Gateway
          </>
        ),
        lists: ['FedEx', 'UPS', 'USPS', 'DHL', 'EasyPost', 'Shippo', 'Gooten'],
      },
      {
        className: 'very-light-pink',
        icon: images.apiSmsGateway,
        title: 'SMS Gateway Integration',
        lists: [
          'Nexmo SMS',
          'TeleSign SMS',
          'Twilio SMS and Lookup',
          'D7SMS',
          'Telnyx',
          'MessageBird SMS',
          'Octopush SMS',
          'ClickSend',
        ],
      },
      {
        className: 'pale',
        icon: images.apiSocialNetwork,
        title: 'Social Network API Solution',
        lists: [
          'Facebook',
          'Instagram',
          'Twitter',
          'YouTube',
          'Pinterest',
          'Snapchat',
          'Tumblr',
          'Viber',
        ],
      },
    ],
  },
  techstacksProps: {
    heading: 'Tech stacks',
    tabContents: [
      {
        tabName: 'Backend',
        tabimages: [
          {
            src: images.iconNodeJS,
            alt: 'Node.js',
          },
          {
            src: images.iconNETCore,
            alt: '.Net Core',
          },
          {
            src: images.iconAspNET,
            alt: 'asp.Net (C#)',
          },
          {
            src: images.iconPHP,
            alt: 'PHP',
          },
          {
            src: images.iconPython,
            alt: 'Python',
          },
          {
            src: images.iconJava,
            alt: 'Java',
          },
          {
            src: images.iconRubyOnRails,
            alt: 'Ruby On Rails',
          },
          {
            src: images.iconGo,
            alt: 'Go',
          },
        ],
      },
      {
        tabName: 'Frontend',
        tabimages: [
          {
            src: images.iconElectron,
            alt: 'Electron',
          },
          {
            src: images.iconNative,
            alt: 'Native',
          },
          {
            src: images.iconTypeScript,
            alt: 'TypeScript',
          },
          {
            src: images.iconXamarin,
            alt: 'Xamarin',
          },
          {
            src: images.iconVue,
            alt: 'Vue',
          },
          {
            src: images.iconReact,
            alt: 'React',
          },
          {
            src: images.iconReactNative,
            alt: 'ReactNative',
          },
          {
            src: images.iconWPF,
            alt: 'WPF',
          },
          {
            src: images.iconAdnroid,
            alt: 'Adnroid',
          },
          {
            src: images.iconJavaScript,
            alt: 'JavaScript',
          },
          {
            src: images.iconAngularJS,
            alt: 'AngularJS',
          },
          {
            src: images.iconiOS,
            alt: 'iOS',
          },
          {
            src: images.iconUnity,
            alt: 'Unity',
          },
        ],
      },
      {
        tabName: 'Databases',
        tabimages: [
          {
            src: images.iconGroup32,
            alt: 'Group32',
          },
          {
            src: images.iconCloudera,
            alt: 'Cloudera',
          },
          {
            src: images.iconGroup42,
            alt: 'Group42',
          },
          {
            src: images.iconMySQL,
            alt: 'MySQL',
          },
          {
            src: images.iconRethinkDB,
            alt: 'RethinkDB',
          },
          {
            src: images.iconFirestore,
            alt: 'Firestore',
          },
          {
            src: images.iconSQLServer,
            alt: 'SQLServer',
          },
          {
            src: images.iconPostageSQL,
            alt: 'PostageSQL',
          },
          {
            src: images.iconOracle,
            alt: 'Oracle',
          },
          {
            src: images.iconhbase,
            alt: 'hbase',
          },
          {
            src: images.iconMAPR,
            alt: 'MAPR',
          },
          {
            src: images.iconHortonworks,
            alt: 'Hortonworks',
          },
          {
            src: images.iconHadoop,
            alt: 'Hadoop',
          },
          {
            src: images.iconFirebase,
            alt: 'Firebase',
          },
          {
            src: images.iconCouchbase,
            alt: 'Couchbase',
          },
          {
            src: images.iconNeptune,
            alt: 'Neptune',
          },
          {
            src: images.iconRedis,
            alt: 'Redis',
          },
          {
            src: images.iconMongoDB,
            alt: 'MongoDB',
          },
          {
            src: images.iconcassandra,
            alt: 'cassandra',
          },
          {
            src: images.iconElasticSearch,
            alt: 'ElasticSearch',
          },
        ],
      },
      {
        tabName: 'CMS',
        tabimages: [
          {
            src: images.iconSuccessfactors,
            alt: 'Successfactors',
          },
          {
            src: images.iconWordpress,
            alt: 'Wordpress',
          },
          {
            src: images.iconMagento,
            alt: 'Magento',
          },
          {
            src: images.iconCanvas,
            alt: 'Canvas',
          },
          {
            src: images.iconLitmus,
            alt: 'Litmus',
          },
          {
            src: images.iconLiferay,
            alt: 'Liferay',
          },
          {
            src: images.iconShopify,
            alt: 'Shopify',
          },
          {
            src: images.iconDocebo,
            alt: 'Docebo',
          },
          {
            src: images.iconMoodle,
            alt: 'Moodle',
          },
          {
            src: images.iconUmbraco,
            alt: 'Umbraco',
          },
          {
            src: images.iconSitecore,
            alt: 'Sitecore',
          },
          {
            src: images.iconTotara,
            alt: 'Totara',
          },
          {
            src: images.iconAdapt,
            alt: 'Adapt',
          },
        ],
      },
      {
        tabName: 'Cloud',
        tabimages: [
          {
            src: images.iconGooglecloud,
            alt: 'Google cloud',
          },
          {
            src: images.icontwilio,
            alt: 'twilio',
          },
          {
            src: images.iconAlgolia,
            alt: 'Algolia',
          },
          {
            src: images.iconTalend,
            alt: 'Talend',
          },
          {
            src: images.iconSpluck,
            alt: 'Spluck',
          },
          {
            src: images.iconAWS,
            alt: 'AWS',
          },
          {
            src: images.iconAlfresco,
            alt: 'Alfresco',
          },
          {
            src: images.iconStripe,
            alt: 'Stripe',
          },
          {
            src: images.iconSharePoint,
            alt: 'SharePoint',
          },
          {
            src: images.iconApigee,
            alt: 'Apigee',
          },
          {
            src: images.iconPivotal,
            alt: 'Pivotal',
          },
          {
            src: images.iconSalesforce,
            alt: 'Salesforce',
          },
          {
            src: images.iconDocker,
            alt: 'Docker',
          },
          {
            src: images.iconMulesoft,
            alt: 'Mulesoft',
          },
          {
            src: images.iconNexmo,
            alt: 'Nexmo',
          },
          {
            src: images.iconkubernetes,
            alt: 'kubernetes',
          },
          {
            src: images.iconAzure,
            alt: 'Azure',
          },
        ],
      },
      {
        tabName: 'Testing',
        tabimages: [
          {
            src: images.iconSoupUI,
            alt: 'SoupUI',
          },
          {
            src: images.iconMochas,
            alt: 'Mochas',
          },
          {
            src: images.iconAppium,
            alt: 'Appium',
          },
          {
            src: images.iconHoverfly,
            alt: 'Hoverfly',
          },
          {
            src: images.iconJMeter,
            alt: 'JMeter',
          },
          {
            src: images.iconKatalon,
            alt: 'Katalon',
          },
          {
            src: images.iconSelenium,
            alt: 'Selenium',
          },
          {
            src: images.iconGatling,
            alt: 'Gatling',
          },
          {
            src: images.iconSaucelabs,
            alt: 'Saucelabs',
          },
        ],
      },
      {
        tabName: 'DevOps',
        tabimages: [
          {
            src: images.iconTravisCI,
            alt: 'TravisCI',
          },
          {
            src: images.iconCircleCI,
            alt: 'CircleCI',
          },
          {
            src: images.iconCodeship,
            alt: 'Codeship',
          },
          {
            src: images.icongradle,
            alt: 'gradle',
          },
          {
            src: images.iconJenkins,
            alt: 'Jenkins',
          },
        ],
      },
    ],
  },
  SimformGuaranteeData: {
    title: (
      <>
        Simform <span className="highlight-head">Guarantee</span>{' '}
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    ButtonText: 'Work with us',
    Link: '/contact/',
    GuaranteeList: [
      {
        icon: images.sgFlexibleIcon,
        guaranteeTitle: 'Flexible, efficient scaling',
        guaranteeInfo: 'Expand or reduce your remote team size on demand.',
      },
      {
        icon: images.sgTeamsIcon,
        guaranteeTitle: 'Team of 1,000+ engineers',
        guaranteeInfo:
          'Access vetted experts in the tech stack of your choice.',
      },
      {
        icon: images.sgLongTermIcon,
        guaranteeTitle: 'Long-term predictability',
        guaranteeInfo: 'Simform’s average engagement duration is 2 years.',
      },
    ],
  },
  faqSectionProps: {
    heading: 'FAQs',
    faqProps: [
      {
        faqTitle: 'What are custom APIs? Why do I need them?',
        faqPara: (
          <>
            Custom APIs allow businesses to manage software-to-software
            integration between different apps smoothly. For example, the
            ability to share your favorite Spotify song on Instagram stories is
            due to the power of custom APIs. It has alleviated the way we
            assemble our applications. By making use of one another’s
            functionality, apps have enhanced the way they communicate with each
            other. In modern app development services, custom APIs play an
            important role since they allow us to innovate faster.
            <br /> <br />
            In general, businesses have their own specific requirements. Using
            the readily available APIs won’t be sufficient to cater to their own
            unique integration needs. This is where businesses are required to
            develop custom APIs that fits their requirement and helps in better
            product development.
          </>
        ),
      },

      {
        faqTitle: 'What is a 3rd Party API integration?',
        faqPara: (
          <>
            The 3rd party API integration is the integration of APIs developed
            by a 3rd party (Facebook, Twitter) in one's custom software to gain
            partial access to services, features or functionalities for better
            business process.
            <br /> <br />A 3rd party API is an API developed by a 3rd party. By
            integrating these APIs, 1st parties can gain partial access to the
            features and functionalities of 3rd party services. Next time when
            you book an Uber, take a close look at the Maps. These maps are
            available due to the 3rd party integration of Google Maps API with
            Uber.
          </>
        ),
      },

      {
        faqTitle: 'What are the types of API?',
        faqPara: (
          <>
            There are 7 types of APIs, however, the most commonly used are web
            services APIs. These are a piece of a software or system available
            that provides access to its services through a dedicated URL over
            the internet. These types of APIs pass the information in a way that
            is easy for other software to understand or parse. For example,
            Flicker API http://www.flickr.com/services/api/.
            <br /> <br />
            Web service APIs uses HTTP or HTTPS for information exchange.
            Whenever the client software wants to communicate, it sends an HTTP
            request which is returned with a HTTP response from the API. During
            these requests, the information is passed in the URL itself. The
            common types of web services API are
            <br /> <br />
            - SOAP
            <br />
            - XML-RPC
            <br />
            - REST
            <br />
            - JSON-RPC
            <br /> <br />
            However, the widely used web service API is REST. REST APIs are
            known for providing faster communication due to less usage of
            internet bandwidth.
          </>
        ),
      },

      {
        faqTitle: 'What is your API development process?',
        faqPara: (
          <>
            To deliver API development services with the user-centric approach,
            we have divided the whole process into simple steps:
            <br /> <br />
            1: Understand the API’s purpose and audience
            <br />
            2: Align your Team
            <br />
            3: Develop your API Incrementally
            <br />
            4: Define KPIs
            <br />
            5: Collecting and Acting on Feedback
            <br />
            6: Marketing the API
          </>
        ),
      },

      {
        faqTitle: 'How do you make working remotely successful?',
        faqPara: (
          <>
            Managing a remote team is not easy. There are critical mistakes you
            can make when trying to leverage the vast amounts of remote talent.
            <br />
            <br />
            Simform team integrates into your team, participating in standup and
            scrum meetings, weekly demos, weekly retrospectives.
            <br />
            <br />
            <strong>Daily stand-ups</strong> - We do daily stand-ups where
            everyone gets on a video chat and tells you what they are working on
            that day and the previous day.
            <br />
            <br />
            <strong>Weekly demonstrations</strong> - This one is simple: get
            everybody on a video chat, share screens, have people show their
            work, and then talk about it.
            <br />
            <br />
            <strong>Weekly Retrospectives</strong> - A weekly retrospective is
            where you all collectively review what went well and what could have
            been improved based on the demo.
          </>
        ),
      },
    ],
    contactHeading: 'Have more questions?',
    contactPara:
      'Let us know and our experts will get in touch with you ASAP. ',
    supportImg: images.contactLadySm,
    buttonName: 'Talk to our experts',
    buttonLink: '/contact/',
  },

  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink: 'https://www.simform.com/blog/api-security-best-practices/',
        featurecardTitle:
          'API Security Best Practices for Web Apps, Rest APIs and API Gateways',
      },
      {
        blogLink: 'https://www.simform.com/blog/twilio-alternatives/',
        featurecardTitle:
          'Top 10 Twilio Alternatives: A Guide to Choose Communication APIs for your Project',
      },
      {
        blogLink:
          'https://www.simform.com/blog/build-virtual-call-center-software/',
        featurecardTitle:
          'Why You Need to Build a Virtual Call Center Software?',
      },
    ],
  },
}
