import React, { memo } from 'react'
import Layout from 'components/Layout/Layout'
import Banner from 'components/Banner/Banner'
import CustomDevelopment from 'components/CustomDevelopment/CustomDevelopment'
import styled from 'styled-components'
import CaseStudySlider from 'components/CaseStudySlider/CaseStudySlider'
import BuildApplication from 'components/BuildApplication/BuildApplication'
import SEO from 'components/Seo'
import { APIIntegrationDevProps } from 'components/Props/api-integration-development'
import { md } from 'config/variables'
import { graphql } from 'gatsby'
import { images } from 'config/images'

import NewBenefitWork from 'components/NewBenefitWork/NewBenefitWork'
import CustomerReviewSlider from 'components/CustomerReviewSlider/CustomerReviewSlider'
import DevelopSoftAgile from 'components/DevelopSoftAgile/DevelopSoftAgile'
import IndustriesServe from 'components/IndustriesServe/IndustriesServe'
import Techstacks from 'components/Techstacks/Techstacks'
import APISolution from 'components/APISolutions/APISolution'
import FaqSection from 'components/FaqSection/FaqSection'
import FeatureResources from 'components/FeatureResources/FeatureResources'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import ProcessFlow from 'components/ProcessFlow/ProcessFlow'
import SimformGuaranteeSec from 'components/SimformGuaranteeSec/SimformGuaranteeSec'

export const APIIntegrationDevPage = styled.div`
  .banner {
    .banner-content {
      max-width: 840px;
      p {
        max-width: 830px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
    }
  }
  .buildapp-section {
    .heading-wrapper {
      max-width: 700px;
    }
    .heading-para {
      max-width: 880px;
    }
  }
  .feature-resource-section {
    background-image: linear-gradient(to top, #eef2ff, #fff);
    .contact-info-card {
      display: none;
    }
  }
  .developsoft-section {
    ${md(`
      display: none;
    `)}
  }
  .industries-service {
    ${md(`
      display: none;
    `)}
  }
  .getintouch-section {
    .awards-recognition-blk {
      ${md(`
        display: none;
      `)}
    }
  }
`

const APIIntegrationDev = memo(props => {
  const { data } = props
  const { location } = props
  // const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent: true })

  let processFlowItem = [
    props.data.process1.childImageSharp.fluid,
    props.data.process2.childImageSharp.fluid,
    props.data.process3.childImageSharp.fluid,
    props.data.process4.childImageSharp.fluid,
    props.data.process5.childImageSharp.fluid,
  ]
  let posterImage = [props.data.posterThumbImage.childImageSharp.fluid]
  return (
    <Layout
      mainClass="api-integration-dev services-page"
      location={location}
      data={{
        img: images.contactCruben,
        img2x: images.contactCruben2x,
        name: 'Ruben Ramirez',
        description:
          "Simplifying organization's digital transformation by building interactive software products.",
      }}
    >
      <SEO
        title="API Integration Services - API Development"
        description="Simform’s API Integration services will help you integrate existing business systems or third-party APIs to enable data and feature sharing in your applications."
        keywords={[`simform`]}
        links={[
          {
            href:
              'https://www.simform.com/services/api-integration-development/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content:
              'https://www.simform.com/services/api-integration-development/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <APIIntegrationDevPage>
        <Banner
          bannerData={APIIntegrationDevProps}
          showBannerImage={true}
          {...props}
        />
        <div className="lazy-load-div">
          <BuildApplication buildApplicationData={APIIntegrationDevProps} />
          <CustomDevelopment
            customServicedata={APIIntegrationDevProps.customDevProps}
          />
        </div>
        <ProcessFlow
          ProcessFlowData={APIIntegrationDevProps.workingBenefitsProps}
          items={processFlowItem}
        />
        <CaseStudySlider caseStudydata={APIIntegrationDevProps} {...props} />
        <CustomerReviewSlider
          customReviewSliderdata={APIIntegrationDevProps}
          {...props}
        />
        <DevelopSoftAgile developSoftAgiledata={APIIntegrationDevProps} />
        <IndustriesServe industriesServedata={APIIntegrationDevProps} />
        <Techstacks techstacksdata={APIIntegrationDevProps} />
        <APISolution apisolutionData={APIIntegrationDevProps} />
        <SimformGuaranteeSec
          SimformGuaranteeProps={APIIntegrationDevProps.SimformGuaranteeData}
          posterImage={posterImage}
        />
        <FaqSection faqSectiondata={APIIntegrationDevProps} />
        <FeatureResources
          featureresourceData={APIIntegrationDevProps}
          {...props}
        />
      </APIIntegrationDevPage>
    </Layout>
  )
})

export default APIIntegrationDev

export const query = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/api-dev-banner@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1297) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case1: file(relativePath: { regex: "/case-onlymob@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case2: file(relativePath: { regex: "/case-safari@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case3: file(relativePath: { regex: "/case-ipad@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case4: file(relativePath: { regex: "/case-mobile@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case5: file(relativePath: { regex: "/redbull-case@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication1: file(
      relativePath: { regex: "/blog-api-security@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication2: file(
      relativePath: { regex: "/blog-twilio-alternatives@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication3: file(
      relativePath: { regex: "/blog-virtual-call-center@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    servicebenefit: file(
      relativePath: { regex: "/enterprise-benefit-banner@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 749) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    olivia: file(relativePath: { regex: "/olivia.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    justin: file(relativePath: { regex: "/justin.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ritwik: file(relativePath: { regex: "/ritwik.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    danielle: file(relativePath: { regex: "/danielle.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jawann: file(relativePath: { regex: "/jawann.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jansen: file(relativePath: { regex: "/jansen.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jeff: file(relativePath: { regex: "/Jeff.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    andy: file(relativePath: { regex: "/andy.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process1: file(relativePath: { regex: "/ill-businessmovement@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process2: file(relativePath: { regex: "/ill-proponentsystem@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process3: file(relativePath: { regex: "/review-assessment@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process4: file(relativePath: { regex: "/standardized-api-dev@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process5: file(relativePath: { regex: "/api-design-scale@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    posterThumbImage: file(relativePath: { regex: "/poster-img.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
